export const currencies = {
  84531: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "ETH" },
  ],
  97: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    { address: "0xe9e7cea3dedca5984780bafc599bd69add087d56", symbol: "BUSD" },
    { address: "0x55d398326f99059ff775485246999027b3197955", symbol: "USDT" },
  ],
 56: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "BNB" },
    
  ],
  137: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "MATIC" },
    
  ],
  
  default: [
    { address: "0x0000000000000000000000000000000000000000", symbol: "ETH" },
    // { address: "0xe9e7cea3dedca5984780bafc599bd69add087d56", symbol: "BUSD" },
    // { address: "0x55d398326f99059ff775485246999027b3197955", symbol: "USDT" },
  ],
};
