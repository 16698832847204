import ethCoin from "../images/coins/eth-coin.png";
import bnbCoin from "../images/coins/bnb-coin.png";
import polyCoin from "../images/coins/poly-coin.png"

export const supportNetwork = {



  56: {
    name: "BNB Mainnet",
    chainId: 56,
    rpc: "https://bsc-mainnet.public.blastapi.io",
    image: bnbCoin,
    symbol: "BNB",
  },
  97: {
    name: "BNB Testnet",
    chainId: 97,
    rpc: "https://bsc-testnet-rpc.publicnode.com",
    image: bnbCoin,
    symbol: "BNB",
  },
 
  default: {
    name: "BNB Mainnet",
    chainId: 56,
    rpc: "https://bsc-mainnet.public.blastapi.io",
    image: bnbCoin,
    symbol: "BNB",
  },
};

export const networkLists = [
  { code: 0, chainId: 137, label: 'Polygon Mainnet', image: polyCoin },
  { code: 0, chainId: 97, label: 'BNB Chain Testnet', image: bnbCoin },
  { code: 0, chainId: 56, label: 'BNB Chain', image: bnbCoin },
  { code: 0, chainId: 84531, label: 'BASE Chain', image: ethCoin }
]


export const RPC_URLS = {
  137: "https://polygon-rpc.com",
  97: "https://data-seed-prebsc-1-s1.binance.org:8545",
  56: "https://bsc-dataseed1.ninicoin.io",
  84531: "https://base-goerli.public.blastapi.io",
};


