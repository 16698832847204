import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import poolManagerAbi from '../json/poolManager.json'; // Adjust the path if necessary
import presalePoolAbi from '../json/presalePool.json'; // Adjust the path if necessary
import './Trending.css'; // Import the CSS file for styling

const Trending = () => {
  const [symbols, setSymbols] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPools = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider('https://bsc-testnet.public.blastapi.io');
        const contractAddress = '0x43fb080E4cc7f76b1E93168A0aFEc924F7f8Ae07';
        
        const poolManagerContract = new ethers.Contract(contractAddress, poolManagerAbi, provider);
        const poolAddresses = await poolManagerContract.getAllPools();

        const poolDataPromises = poolAddresses.map(async (poolAddress) => {
          const poolContract = new ethers.Contract(poolAddress, presalePoolAbi, provider);
          const tokenAddress = await poolContract.token();

          const tokenContract = new ethers.Contract(tokenAddress, [
            "function symbol() view returns (string)"
          ], provider);

          const symbol = await tokenContract.symbol();
          const totalRaised = await poolContract.totalRaised();

          return { symbol, totalRaised: totalRaised.toString(), poolAddress };
        });

        const poolsData = await Promise.all(poolDataPromises);

        // Sort pools by totalRaised in descending order
        poolsData.sort((a, b) => b.totalRaised - a.totalRaised);

        setSymbols(poolsData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPools();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="symbol-list">
      Trending: 
      {symbols.map((pool, index) => (
        <span key={index} className="symbol-item">
          <span className="symbol-index">#{index + 1}</span> 
          <Link to={`/presale-details/${pool.poolAddress}`} className="symbol-link">
            {pool.symbol}
          </Link>
        </span>
      ))}
    </div>
  );
};

export default Trending;
